* {
    -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
}

html, body { /* disable scrolling */
    margin: 0;
    height: 100%;
    overflow: hidden;
}

body {
    -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;             /* prevent webkit from resizing text to fit */
    -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
    background: #fa9c8c url(img/background.png) no-repeat fixed center;
    background-size: cover;
    font-family: 'GaramondBT', serif, system-ui, -apple-system, -apple-system-font;
    font-size: calc(14pt + 0.35vw);
    width:100%;
    color: white;
}

p {
    margin-top: 0;
}

a {
    color: white;
}

a:hover, a:active {
    color: #d1d1d1;
}

@font-face {
    font-family: 'GaramondBT';
    src: url(fonts/OriginalGaramondBT-Roman.otf);
    font-style: normal;
}

@font-face {
    font-family: 'GaramondBT';
    src: url(fonts/OriginalGaramondBT-Italic.otf);
    font-style: italic;
}

.text {
    width: 80%;
    max-width: 580px;
    max-height: 85%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-right: 0.8em;
    line-height: 1.3em;
    -webkit-user-select: text;
}

.text img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 300px;
    height: auto;
}

button {
    border: 0 !important;
    outline: 0 !important;
    padding: 0;
    cursor: pointer;
    height: 80px;
    min-width: 80px;
    background: transparent no-repeat scroll;
    background-size: 100% auto;
    transition: opacity 80ms ease-in 100ms;
}

button:active {
    transition: none;
    opacity: 0.5;
}

/* scroll-snap */

.carousel {
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-flow: row nowrap;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
}

.carousel > .slide {
    flex: none;
    width: 100%;
    position: relative;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel.snapping > .slide {
    scroll-snap-align: center;
}

/* slides */

.title {
    background: url(img/title.png) no-repeat scroll center;
    background-size: contain;
    height: 80%;
    width: 90%;
}

.player {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.player > * {
    padding: 15px 0 15px 0;
}

.scroll-button {
    position: absolute;
    background-size: auto 100%;
    height: 60px;
    width: 45px;
    top: 50%;
    transition: opacity 400ms ease-in-out;
}

@media (max-width: 820px) {
    .scroll-button {
        display: none;
    }
}

.scroll-button:disabled {
    cursor: default;
    opacity: 0%;
    transition-duration: 100ms;
}

.scroll-button.back {
    background-image: url(img/slide-back.png);
    left: calc(25% - 180px);
}

.scroll-button.fwd {
    background-position: right;
    background-image: url(img/slide-fwd.png);
    right: calc(25% - 180px);
}

/* Player controls */

.controls {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

button.ffw {
    transform: translate(-8px);
    background-image: url(img/wind-fw.png);
}

button.rew {
    transform: translateY(16px);
    background-image: url(img/wind-back.png);
}

button.playpause {
    background-image: url(img/play-pause.png);
    width: 160px;
    background-size: 160px 160px;
}

.playpause.play {
    background-position: 50% 0;
}

.playpause.pause {
    background-position: 50% -80px;
}

.playpause.loading {
    animation: throb 2.3s infinite;
}

@keyframes throb {
    0%   {opacity: 1.0;}
    50%  {opacity: 0.0;}
    100% {opacity: 1.0;}
}

/* timeline */

.timeline {
    transform: rotate(-8deg);
    height: 30px;
    max-width: 230px;
    width: 60%;
    margin-bottom: -20px;
    margin-top: -20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.blip {
    display: flex;
    border-radius: 100%;
    height: 5px;
    width: 5px;
    background-color: #ddd;
    transition-property: background-color, height, width;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
}

.blip.lit {
    background-color: white;
}

.blip.big {
    height: 9px;
    width: 9px;
}

/* Fragment titles - generic */

.fragment-container {
    height: 40%;
    max-height: 325px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.fragment {
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom 10%;
    position: absolute;
    width: 80%;
    max-width: 360px;
    height: 40%;
    max-height: 325px;
    transition: transform 0.6s, opacity 0.6s;
}

.fragment.coming {
    transform: translate(100px, -20px);
    opacity: 0;
}

.fragment.in {
    transform: translate(0, 0);
    opacity: 1;
    transition-timing-function: ease-out, ease-in;
    transition-delay: 0.4s;
}

.fragment.out {
    transform: translate(-100px, 20px);
    opacity: 0;
    transition-timing-function: ease-in, ease-out;
    transition-delay: 0s;
}

/* Fragment titles - individual */

.urinal {
    background-image: url(./img/fragments/urinal.png);
}

.bathhouse {
    background-image: url(./img/fragments/bathhouse.png);
}

.biographies {
    background-image: url(./img/fragments/biographies.png);
}

.clocks {
    background-image: url(./img/fragments/clocks.png);
}

.clocktowers {
    background-image: url(./img/fragments/clocktowers.png);
}

.duster {
    background-image: url(./img/fragments/duster.png);
}

.dutch {
    background-image: url(./img/fragments/dutch.png);
}

.exlibris {
    background-image: url(./img/fragments/exlibris.png);
}

.faggot {
    background-image: url(./img/fragments/faggot.png);
}

.ficus {
    background-image: url(./img/fragments/ficus.png);
}

.futurelovers {
    background-image: url(./img/fragments/futurelovers.png);
}

.illustration {
    background-image: url(./img/fragments/illustration.png);
}

.intro {
    background-image: url(./img/fragments/intro.png);
}

.mirror {
    background-image: url(./img/fragments/mirror.png);
}

.novels {
    background-image: url(./img/fragments/novels.png);
}

.pamphlet {
    background-image: url(./img/fragments/pamphlet.png);
}

.pencil {
    background-image: url(./img/fragments/pencil.png);
}

.persian {
    background-image: url(./img/fragments/persian.png);
}

.postcard {
    background-image: url(./img/fragments/postcard.png);
}

.spiderplant {
    background-image: url(./img/fragments/spiderplant.png);
}

.watercolor {
    background-image: url(./img/fragments/watercolor.png);
}

.weeping {
    background-image: url(./img/fragments/weeping.png);
}
